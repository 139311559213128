/* global window, FormData */
import { Promise } from 'es6-promise'
import * as axios from 'axios'
import qs from 'qs'
import {  clearInfo } from 'utils/storage'
import CryptoJS from 'crypto-js'

export const SITE_URL = '/'
axios.defaults.baseURL = '/api'
axios.defaults.timeout = 600000
axios.defaults.withCredentials = true;

const requestConfig = {
  headers: {
    'Content-Type': 'text/plain',
  },
}

const requestConfigJSON = {
  headers: {
    'Content-Type': 'application/json',
  },
}

const requestConfigFormData = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}



export const encrypt = ( str ) => {

  const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY
  const CRYPTO_IV = process.env.REACT_APP_CRYPTO_IV

  return CryptoJS.AES.encrypt(str, CryptoJS.enc.Utf8.parse(CRYPTO_KEY), {
    iv: CryptoJS.enc.Utf8.parse(CRYPTO_IV),
    mode: CryptoJS.mode.CBC
  }).toString()
}

const withPromise = axiosInstance =>
  new Promise((resolve, reject) => {
    axiosInstance.then(
      res => {
        resolve(res.data)
      },
      err => {
        // service is unavailable
        if (!err.response) {
          reject(new Error({ status: 503, error: 'Service is unavailable' }))
          return
        }

        if (err.response.status === 403) {
          // redirect to the homepage if permission is denied
          // document.location = '/'
        } else if (
          // redirect to login page if user is not authenticated
          err.response.status === 401
        ) {
          // clear user from storage
          clearInfo()
          // redirect
          if (window.location.pathname.indexOf('/login') === -1) {
            document.location = '/login'
          }
        }

        // general error
        reject(
          Object.assign(err.response.data || {}, {
            status: err.response.status,
          })
        )
      }
    )
  })

export function create(config) {
  return withPromise(
    axios({
      ...config,
      url: config.url,
    })
  )
}

export function get(endpoint, query) {
  const config = requestConfig

  let url = endpoint
  if (query) url += `?${qs.stringify(query)}`

  return withPromise(axios.get(url, config))
}

export function post(endpoint, body, config = {}) {
   config = Object.assign(config, requestConfig)

  let requestBody = body

  if (body instanceof FormData) {
    config = Object.assign(config, requestConfigFormData)
  } else if (typeof body === 'object') {
    config = Object.assign(config, requestConfigJSON)
    requestBody = JSON.stringify(body)
  }

  return withPromise(axios.post(endpoint, requestBody, config))
}

export function patch(endpoint, body) {
  let config = requestConfig
  let requestBody = body

  if (body instanceof FormData) {
    config = Object.assign(config, requestConfigFormData)
  } else if (typeof body === 'object') {
    config = Object.assign(config, requestConfigJSON)
    requestBody = JSON.stringify(body)
  }

  return withPromise(axios.patch(endpoint, requestBody, config))
}

export function put(endpoint, body) {
  let config = requestConfig
  let requestBody = body

  if (body instanceof FormData) {
    config = Object.assign(config, requestConfigFormData)
  } else if (typeof body === 'object') {
    config = Object.assign(config, requestConfigJSON)
    requestBody = JSON.stringify(body)
  }

  return withPromise(axios.put(endpoint, requestBody, config))
}

export function del(endpoint) {

  return withPromise(axios.delete(endpoint, requestConfig))
}



export function filterNestedObjects(obj) {
  let ret = {}
  for(const k in obj) {
    if(typeof obj[k] === 'object') continue
    ret[k]=obj[k]
  }
  return ret
}
