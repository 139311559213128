import React from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { Spinner } from 'reactstrap'
import { withTranslation, WithTranslation } from 'react-i18next'

import { getCompanyInfo } from 'store/company/actions'
import { getCompanyInfo as getCompanyInfoSelector } from 'store/company/reducer'
import { selectProfile } from 'store/profile/reducer'
import './GuestLayout.scss'
import UnderMaintenance from './UnderMaintenance'

interface Props {
  company: CompanyInfo
  innerComponent: any
  pageName: string
  user: User
  dispatch(action: any): any
}

interface States {
  isLoading: boolean
  notFound: boolean
}

class GuestLayout extends React.Component<Props & WithTranslation, States> {
  state = {
    isLoading: false,
    notFound: false
  }

  constructor(props) {
    super(props)

    if (this.props.user) {
      const maintenance = this.props.user.company && this.props.user.company.maintenance
      if (!maintenance) {
        this.props.dispatch(replace('/home'))
      }
    }

    if (!this.props.company) {
      this.state.isLoading = true
      this.props.dispatch(getCompanyInfo()).then(
        () => {
          this.setState({ isLoading: false })
        },
        () => {
          this.setState({ isLoading: false, notFound: true })
        }
      )
    }
  }

  render() {
    const { company, user } = this.props
    const { isLoading, notFound } = this.state
    const InnerComponent = this.props.innerComponent
    console.log(this.props);
    

    if (isLoading) {
      return (
        <div className="spinner-container">
          <Spinner color="primary" />
        </div>
      )
    }

    if (notFound) {
      return (
        <div className="alert alert-danger" role="alert">
          <b>error</b>: incorrect url address. Please ask admin for correct url address for your company
        </div>
      )
    }

    const maintenance = user && user.company && user.company.maintenance

    return (
      <div
        className="login-screen"
        style={company.background ? { backgroundImage: `url(/api/storage/images/${company.background})` } : {}}
      >
        {
          company.logo
          && (<img className='login-screen-logo' alt='logo' src={`/api/storage/images/${company.logo}`} height={50} />)
        }

        {maintenance ?
          <UnderMaintenance {...this.props} />
          : <InnerComponent {...this.props} />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectProfile(state),
  company: getCompanyInfoSelector(state),
})

export default withTranslation()(connect(mapStateToProps)(GuestLayout))
